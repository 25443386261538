@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "wix-bold";
  src: url("./fonts/WixMadeforDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "wix-reg";
  src: url("./fonts/WixMadeforDisplay-Regular.ttf") format("truetype");
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #000000 #2c343e;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 8px;
  width: 8px;
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #5a6879;
}

*::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #24282d;
}
